import axios from 'axios';
import { PAYMENT_DOMAIN_API_URL } from 'utils';

import { StopRenewSubscription } from './Payments.types';

export const fetchStopRenewSubscription = ({
  customerId,
  comment
}: StopRenewSubscription) =>
  axios.post(
    `${PAYMENT_DOMAIN_API_URL}/payments/subscriptions/stop/${customerId}`,
    {
      comment
    }
  );
