import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomerFullFormData, fetchUpdateCustomer } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useUpdateCustomer = (isPaymentDomain?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CustomerFullFormData & { id: string }) =>
      fetchUpdateCustomer(data, isPaymentDomain),
    {
      onSuccess(_, { id }) {
        queryClient.invalidateQueries([QueryKey.Customers, id]);
        queryClient.invalidateQueries([QueryKey.Customers]);
      },
      onError(error: AxiosError<AxiosErrorData>) {}
    }
  );
};
