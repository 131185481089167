import axios from 'axios';
import { PAYMENT_DOMAIN_API_URL } from 'utils';

export const fetchDeleteCustomer = ({
  id,
  adminComment
}: {
  id: string;
  adminComment: string;
}) =>
  axios.post(`${PAYMENT_DOMAIN_API_URL}/users/delete/${id}`, { adminComment });
