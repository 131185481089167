import {
  CustomerFullFormData,
  CustomerResponse,
  ENDPOINTS,
  UpdateCustomerRequest
} from 'api';
import axios, { AxiosResponse } from 'axios';
import { PAYMENT_DOMAIN_API_URL } from 'utils';

import { serializeCustomerRequestData } from '../Customers.utils';

export const fetchUpdateCustomer = async (
  customerDetails: CustomerFullFormData & { id: string },
  isPaymentDomain?: boolean
): Promise<void> => {
  const { id, adminComment, ...rest } = customerDetails;

  await axios.patch<
    CustomerResponse,
    AxiosResponse<CustomerResponse>,
    Partial<UpdateCustomerRequest>
  >(
    isPaymentDomain
      ? `${PAYMENT_DOMAIN_API_URL}/users/${customerDetails.id}`
      : `${ENDPOINTS.CUSTOMER}/${customerDetails.id}`,
    {
      ...(adminComment && { adminComment }),
      ...serializeCustomerRequestData(rest)
    }
  );
};
