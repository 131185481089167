// @ts-nocheck

import {
  InfiniteData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import {
  fetchUpdateGalleryPhoto,
  ModerationGalleryPhotoResponse,
  WithPagination
} from 'api';
import { AxiosError } from 'axios';
import { ModerationPostStatus, QueryKey } from 'enum';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store';
import { AxiosErrorData } from 'types';

export const useUpdateGalleryPhoto = () => {
  const queryClient = useQueryClient();
  const globalFilters = useAppSelector(selectAppFilters);

  return useMutation(fetchUpdateGalleryPhoto, {
    async onSuccess({ data: updatedPhoto }) {
      queryClient.setQueryData(
        [
          QueryKey.ModerationGalleryPhoto,
          ModerationPostStatus.NotAccepted,
          globalFilters
        ],
        (
          prev: InfiniteData<WithPagination<ModerationGalleryPhotoResponse[]>>
        ) => {
          const updatedState = prev.pages.map((item) => ({
            ...item,
            data: item.data
              .map((dataItem) => ({
                ...dataItem,
                photos: dataItem.photos.filter(
                  (photo) => photo.id !== updatedPhoto.id
                )
              }))
              .filter(({ photos }) => !!photos.length)
          }));

          return { ...prev, pages: updatedState };
        }
      );
    },
    onError(error: AxiosError<AxiosErrorData>) {},
    onSettled() {
      queryClient.invalidateQueries([QueryKey.ModerationGalleryPhoto]);
      queryClient.invalidateQueries([QueryKey.ModerationTotal]);
      queryClient.invalidateQueries([QueryKey.Settings]);
      queryClient.invalidateQueries([QueryKey.CustomerGalleryImages]);
    }
  });
};
