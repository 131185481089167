import axios from 'axios';
import { PAYMENT_DOMAIN_API_URL } from 'utils';

import { CancelCustomerSubscription } from './Payments.types';

export const fetchCancelCustomerSubscription = ({
  customerId,
  comment
}: CancelCustomerSubscription) =>
  axios.post(
    `${PAYMENT_DOMAIN_API_URL}/payments/subscriptions/cancel/${customerId}`,
    {
      comment
    }
  );
