import {
  GeneralSettingsResponse,
  Settings,
  UpdateGeneralSettingsRequestData
} from 'api';
import { FormField, Range } from 'enum';
import {
  LengthUnits,
  OptionKey,
  PermissionAction,
  PermissionResource
} from 'enum/api';
import { RequiredNotNullable, ResourceActionPermission } from 'types';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from './FormContent';

export const serializeGeneralSettingResponse = (
  data: GeneralSettingsResponse,
  country: string
): GeneralSettingsFormData => ({
  [GeneralSettingsFormField.Country]: country,
  [GeneralSettingsFormField.SeeAllUsersWithFreePlan]:
    data.canSearchAllUsersFromFreePlan,
  [GeneralSettingsFormField.PercentageForDisplayAnotherGender]:
    data.anotherGenderPercentage ?? undefined,
  [GeneralSettingsFormField.AmountOfProfileViewsBeforePay]:
    data.viewableProfilesNumBeforePay ?? undefined,
  [GeneralSettingsFormField.AmountOfProfileViewsFillProfile]:
    data.viewableProfilesNumBeforeFillProfile ?? undefined,
  [GeneralSettingsFormField.CountOfFollowersToInstagramVerify]:
    data.instagramFollowersMinCount ?? undefined,
  [GeneralSettingsFormField.MinMaxAgeCustomers]: {
    [Range.Start]: data.usersMinAge,
    [Range.End]: data.usersMaxAge
  },
  [GeneralSettingsFormField.PasswordForViewCustomerMessages]: {
    [FormField.Password]: '',
    [FormField.PasswordConfirmation]: ''
  },
  [GeneralSettingsFormField.CorporateEmail]: data.corporateEmail || '',
  [GeneralSettingsFormField.ReportEmail]: data.reportsEmail || '',
  [GeneralSettingsFormField.ContactUsEmail]: data.supportEmail || '',
  [GeneralSettingsFormField.ContactUsTelegramLink]:
    data.supportTelegramLink || '',
  [GeneralSettingsFormField.ContactUsWhatsappLink]:
    data.supportWhatsappLink || '',
  [GeneralSettingsFormField.NotificationEmail]: data.notificationsEmail || '',
  [GeneralSettingsFormField.WelcomeMessageText]: data.welcomeMessageText || '',
  [GeneralSettingsFormField.DefaultLanguage]: data.language || null,
  [GeneralSettingsFormField.Domains]: data.domains || [],
  [GeneralSettingsFormField.SisterCountries]: data.sisterCountries || [],
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessage]:
    data.generalMessageWarnBadWords,
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescription]:
    data.generalProfileWarnBadWords,
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessage]:
    data.generalMessageBlockBadWords,

  [GeneralSettingsFormField.AutoLabelsAfterRegistration]:
    data.autoLabels.length > 0
      ? data.autoLabels.map(({ gender, labels }) => ({
          [FormField.Gender]: gender,
          [FormField.Label]: labels
        }))
      : [
          {
            [FormField.Gender]: null,
            [FormField.Label]: []
          }
        ],
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer]:
    data.specificMessageWarnBadWords.length > 0
      ? data.specificMessageWarnBadWords.map(({ user, badWords }) => ({
          [FormField.User]: {
            [FormField.Id]: user.id,
            [FormField.Email]: user.email
          },
          [FormField.BadWords]: badWords
        }))
      : [
          {
            [FormField.User]: {
              [FormField.Id]: null,
              [FormField.Email]: null
            },
            [FormField.BadWords]: []
          }
        ],
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]:
    data.specificProfileWarnBadWords.length > 0
      ? data.specificProfileWarnBadWords.map(({ user, badWords }) => ({
          [FormField.User]: {
            [FormField.Id]: user.id,
            [FormField.Email]: user.email
          },
          [FormField.BadWords]: badWords
        }))
      : [
          {
            [FormField.User]: {
              [FormField.Id]: null,
              [FormField.Email]: null
            },
            [FormField.BadWords]: []
          }
        ],
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer]:
    data.specificMessageBlockBadWords.length > 0
      ? data.specificMessageBlockBadWords.map(({ user, badWords }) => ({
          [FormField.User]: {
            [FormField.Id]: user.id,
            [FormField.Email]: user.email
          },
          [FormField.BadWords]: badWords
        }))
      : [
          {
            [FormField.User]: {
              [FormField.Id]: null,
              [FormField.Email]: null
            },
            [FormField.BadWords]: []
          }
        ],
  [GeneralSettingsFormField.FreeMessages]:
    data.freeMessagesAmounts.length > 0
      ? data.freeMessagesAmounts.map(
          ({ userType, messagesNum, conversationsNum }) => ({
            [FormField.CustomerType]: userType,
            [FormField.MessageAmount]: messagesNum,
            [FormField.ConversationAmount]: conversationsNum || undefined
          })
        )
      : [
          {
            [FormField.CustomerType]: undefined,
            [FormField.MessageAmount]: undefined,
            [FormField.ConversationAmount]: undefined
          }
        ],
  [GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto]:
    data.viewableProfilesNumBeforePhotosUpload.length > 0
      ? data.viewableProfilesNumBeforePhotosUpload.map(
          ({ userType, profilesNum }) => ({
            [FormField.CustomerType]: userType,
            [FormField.CustomersAmount]: profilesNum
          })
        )
      : [
          {
            [FormField.CustomerType]: undefined,
            [FormField.CustomersAmount]: undefined
          }
        ],
  [GeneralSettingsFormField.RequiredPremiumProfiles]:
    data.requiredPremiumProfiles.length > 0
      ? data.requiredPremiumProfiles.map(({ userType, gender }) => ({
          [FormField.CustomerType]: userType,
          [FormField.Gender]: gender
        }))
      : [
          {
            [FormField.CustomerType]: undefined,
            [FormField.Gender]: undefined
          }
        ],
  [GeneralSettingsFormField.FreeLikesCount]: {
    [FormField.FreeLikesAmount]: data.freeLikesCount ?? undefined,
    [FormField.PremiumLikesAmountPerDay]:
      data.premiumLikesPerDayCount ?? undefined
  },
  [GeneralSettingsFormField.LengthUnit]: data.lengthUnit || '',
  [GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]:
    data.userInteractionRestricts.length > 0
      ? data.userInteractionRestricts.map(
          ({
            currentUserType,
            restrictedUserType,
            countries,
            appliedForFreeProgramType
          }) => ({
            [FormField.CustomerType]: currentUserType,
            [FormField.OtherCustomerType]: restrictedUserType,
            [FormField.Countries]: countries,
            [FormField.EverybodyCanSeeEverybody]: false,
            [FormField.AppliedForFreeProgramType]: appliedForFreeProgramType
          })
        )
      : [
          {
            [FormField.CustomerType]: null,
            [FormField.OtherCustomerType]: null,
            [FormField.Countries]: [],
            [FormField.AppliedForFreeProgramType]: undefined,
            [FormField.EverybodyCanSeeEverybody]: true
          }
        ],
  [GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]:
    data.requiredSendChatAccessRequestProfiles.length > 0
      ? data.requiredSendChatAccessRequestProfiles.map(
          ({
            requestByCustomerTypes,
            requestToCustomerTypes,
            requestByGenders,
            requestToGenders,
            requestByProgramTypes,
            requestToProgramTypes,
            countries
          }) => ({
            [FormField.CustomerTypes]: requestByCustomerTypes,
            [FormField.OtherCustomerTypes]: requestToCustomerTypes,
            [FormField.Genders]: requestByGenders,
            [FormField.OtherGenders]: requestToGenders,
            [FormField.ProgramTypes]: requestByProgramTypes,
            [FormField.OtherProgramTypes]: requestToProgramTypes,
            [FormField.Countries]: countries,
            [FormField.EverybodyCanMessageToEverybody]: false
          })
        )
      : [
          {
            [FormField.CustomerTypes]: [],
            [FormField.OtherCustomerTypes]: [],
            [FormField.Genders]: [],
            [FormField.OtherGenders]: [],
            [FormField.ProgramTypes]: [],
            [FormField.OtherProgramTypes]: [],
            [FormField.Countries]: [],
            [FormField.EverybodyCanMessageToEverybody]: true
          }
        ],
  [GeneralSettingsFormField.RateForAutoBlock]: {
    [FormField.MessageAmount]: data.autoBlockingBadWordsRate?.messagesNum,
    [FormField.Period]: data.autoBlockingBadWordsRate?.period,
    [FormField.BadWords]: data.autoBlockingBadWordsRate?.badWords || []
  },
  [GeneralSettingsFormField.RateForBlockingFloods]: {
    [FormField.MessageAmount]: data.autoBlockingFloodsRate?.messagesNum,
    [FormField.Period]: data.autoBlockingFloodsRate?.period
  },
  [GeneralSettingsFormField.StorageFolderName]: data.storageFolderName,
  [GeneralSettingsFormField.StorageMaxEmailsImagesMale]:
    data.storageMaxEmailsImagesMale,
  [GeneralSettingsFormField.StorageMaxEmailsImagesFemale]:
    data.storageMaxEmailsImagesFemale,
  [GeneralSettingsFormField.StorageMaxNotificationsImagesMale]:
    data.storageMaxNotificationsImagesMale,
  [GeneralSettingsFormField.StorageMaxNotificationsImagesFemale]:
    data.storageMaxNotificationsImagesFemale,
  [GeneralSettingsFormField.UsersShouldRequestProfileDeletion]:
    data.shouldRequestProfileDeletion,
  [GeneralSettingsFormField.EnabledMultiCurrencies]:
    data.enabledMultiCurrencies,
  [GeneralSettingsFormField.PayThroughPaymentDomain]:
    data.payThroughPaymentDomain,
  [GeneralSettingsFormField.StartedConversationsPerDayLimit]:
    data.startedConversationsPerDayCount ?? undefined
});

export const getMockSettingsDefaultValue = (
  settings: Settings
): GeneralSettingsFormData => ({
  [GeneralSettingsFormField.Country]:
    settings[OptionKey.Country].length === 1
      ? settings[OptionKey.Country][0]
      : '',
  [GeneralSettingsFormField.SeeAllUsersWithFreePlan]: false,
  [GeneralSettingsFormField.PercentageForDisplayAnotherGender]: undefined,
  [GeneralSettingsFormField.AmountOfProfileViewsBeforePay]: undefined,
  [GeneralSettingsFormField.AmountOfProfileViewsFillProfile]: undefined,
  [GeneralSettingsFormField.CountOfFollowersToInstagramVerify]: undefined,
  [GeneralSettingsFormField.MinMaxAgeCustomers]: {
    [Range.Start]: 18,
    [Range.End]: 99
  },
  [GeneralSettingsFormField.PasswordForViewCustomerMessages]: {
    [FormField.Password]: '',
    [FormField.PasswordConfirmation]: ''
  },
  [GeneralSettingsFormField.CorporateEmail]: '',
  [GeneralSettingsFormField.ReportEmail]: '',
  [GeneralSettingsFormField.ContactUsEmail]: '',
  [GeneralSettingsFormField.ContactUsTelegramLink]: '',
  [GeneralSettingsFormField.ContactUsWhatsappLink]: '',
  [GeneralSettingsFormField.NotificationEmail]: '',
  [GeneralSettingsFormField.WelcomeMessageText]: '',
  [GeneralSettingsFormField.DefaultLanguage]: null,
  [GeneralSettingsFormField.Domains]: [],
  [GeneralSettingsFormField.SisterCountries]: [],
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessage]: [],
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescription]: [],
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessage]: [],
  [GeneralSettingsFormField.StorageFolderName]: null,
  [GeneralSettingsFormField.StorageMaxEmailsImagesMale]: null,
  [GeneralSettingsFormField.StorageMaxEmailsImagesFemale]: null,
  [GeneralSettingsFormField.StorageMaxNotificationsImagesMale]: null,
  [GeneralSettingsFormField.StorageMaxNotificationsImagesFemale]: null,
  [GeneralSettingsFormField.UsersShouldRequestProfileDeletion]: false,
  [GeneralSettingsFormField.EnabledMultiCurrencies]: false,
  [GeneralSettingsFormField.PayThroughPaymentDomain]: false,
  [GeneralSettingsFormField.StartedConversationsPerDayLimit]: undefined,

  [GeneralSettingsFormField.AutoLabelsAfterRegistration]: [
    {
      [FormField.Gender]: null,
      [FormField.Label]: []
    }
  ],
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer]: [
    {
      [FormField.User]: {
        [FormField.Id]: null,
        [FormField.Email]: null
      },
      [FormField.BadWords]: []
    }
  ],
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]: [
    {
      [FormField.User]: {
        [FormField.Id]: null,
        [FormField.Email]: null
      },
      [FormField.BadWords]: []
    }
  ],
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer]: [
    {
      [FormField.User]: {
        [FormField.Id]: null,
        [FormField.Email]: null
      },
      [FormField.BadWords]: []
    }
  ],
  [GeneralSettingsFormField.FreeMessages]: [
    {
      [FormField.CustomerType]: undefined,
      [FormField.MessageAmount]: undefined,
      [FormField.ConversationAmount]: undefined
    }
  ],
  [GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto]: [
    {
      [FormField.CustomerType]: undefined,
      [FormField.CustomersAmount]: undefined
    }
  ],
  [GeneralSettingsFormField.RequiredPremiumProfiles]: [
    {
      [FormField.CustomerType]: undefined,
      [FormField.Gender]: undefined
    }
  ],
  [GeneralSettingsFormField.FreeLikesCount]: {
    [FormField.FreeLikesAmount]: undefined,
    [FormField.PremiumLikesAmountPerDay]: undefined
  },
  [GeneralSettingsFormField.LengthUnit]: LengthUnits.CM,
  [GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]: [
    {
      [FormField.CustomerType]: null,
      [FormField.OtherCustomerType]: null,
      [FormField.Countries]: [],
      [FormField.AppliedForFreeProgramType]: undefined,
      [FormField.EverybodyCanSeeEverybody]: true
    }
  ],
  [GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]: [
    {
      [FormField.CustomerTypes]: [],
      [FormField.OtherCustomerTypes]: [],
      [FormField.Genders]: [],
      [FormField.OtherGenders]: [],
      [FormField.ProgramTypes]: [],
      [FormField.OtherProgramTypes]: [],
      [FormField.Countries]: [],
      [FormField.EverybodyCanMessageToEverybody]: true
    }
  ],
  [GeneralSettingsFormField.RateForAutoBlock]: {
    [FormField.MessageAmount]: undefined,
    [FormField.Period]: undefined,
    [FormField.BadWords]: []
  },
  [GeneralSettingsFormField.RateForBlockingFloods]: {
    [FormField.MessageAmount]: undefined,
    [FormField.Period]: undefined
  }
});

const serializeAutoBlockingBadWordsRate = (
  data: GeneralSettingsFormData[GeneralSettingsFormField.RateForAutoBlock]
): UpdateGeneralSettingsRequestData['autoBlockingBadWordsRate'] => {
  const { messageAmount, period, badWords } = data;

  if (badWords.length === 0 || !messageAmount || !period) return null;

  return {
    messagesNum: messageAmount,
    period,
    badWords
  };
};
const serializeAutoBlockingFloodsRate = (
  data: GeneralSettingsFormData[GeneralSettingsFormField.RateForBlockingFloods]
): UpdateGeneralSettingsRequestData['autoBlockingFloodsRate'] => {
  const { messageAmount, period } = data;

  if (!messageAmount || !period) return null;

  return {
    messagesNum: messageAmount,
    period
  };
};

const serializeSpecificBadWords = (
  data: GeneralSettingsFormData[
    | GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer
    | GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer
    | GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]
): UpdateGeneralSettingsRequestData['specificMessageWarnBadWords'] =>
  data
    .filter(({ user }) => !!user.id)
    .map(({ user, badWords }) => ({
      userId: user.id as string,
      badWords
    }));

const serializeCustomerRestrictions = (
  restrictions: GeneralSettingsFormData[GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]
): UpdateGeneralSettingsRequestData['userInteractionRestricts'] =>
  restrictions
    .filter(
      (
        data
      ): data is RequiredNotNullable<
        GeneralSettingsFormData[GeneralSettingsFormField.RestrictCustomerFromAnotherCountry][number]
      > => !data.isEverybodyCanSeeEverybody
    )
    .map(
      ({
        customerType,
        otherCustomerType,
        countries,
        appliedForFreeProgramType
      }) => ({
        currentUserType: customerType,
        restrictedUserType: otherCustomerType,
        countries,
        appliedForFreeProgramType
      })
    );

const serializeRequiredSendChatAccessRequestProfiles = (
  restrictions: GeneralSettingsFormData[GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]
): UpdateGeneralSettingsRequestData['requiredSendChatAccessRequestProfiles'] =>
  restrictions
    .filter(
      (
        data
      ): data is RequiredNotNullable<
        GeneralSettingsFormData[GeneralSettingsFormField.RequestChatAccessFromAnotherCountry][number]
      > => !data.isEverybodyCanMessageToEverybody
    )
    .map(
      ({
        customerTypes,
        otherCustomerTypes,
        genders,
        otherGenders,
        programTypes,
        otherProgramTypes,
        countries
      }) => ({
        requestByCustomerTypes: customerTypes,
        requestToCustomerTypes: otherCustomerTypes,
        requestByGenders: genders,
        requestToGenders: otherGenders,
        requestByProgramTypes: programTypes,
        requestToProgramTypes: otherProgramTypes,
        countries
      })
    );

type SerializeGeneralSettingsMap =
  UpdateGeneralSettingsRequestData extends infer U
    ? {
        [K in GeneralSettingsFormField]: {
          [P in keyof U]?: (
            data: GeneralSettingsFormData[K]
          ) => Required<UpdateGeneralSettingsRequestData[P]>;
        };
      }
    : never;

const serializeGeneralSettingsMap: SerializeGeneralSettingsMap = {
  [GeneralSettingsFormField.Country]: {},
  [GeneralSettingsFormField.SeeAllUsersWithFreePlan]: {
    canSearchAllUsersFromFreePlan: (data) => data
  },
  [GeneralSettingsFormField.PercentageForDisplayAnotherGender]: {
    anotherGenderPercentage: (data) => data ?? null
  },
  [GeneralSettingsFormField.AmountOfProfileViewsBeforePay]: {
    viewableProfilesNumBeforePay: (data) => data ?? null
  },
  [GeneralSettingsFormField.AmountOfProfileViewsFillProfile]: {
    viewableProfilesNumBeforeFillProfile: (data) => data ?? null
  },
  [GeneralSettingsFormField.CountOfFollowersToInstagramVerify]: {
    instagramFollowersMinCount: (data) => data ?? null
  },
  [GeneralSettingsFormField.MinMaxAgeCustomers]: {
    usersMinAge: (data) => data.start,
    usersMaxAge: (data) => data.end
  },
  [GeneralSettingsFormField.PasswordForViewCustomerMessages]: {
    viewConversationsPassword: (data) => data.password || undefined
  },
  [GeneralSettingsFormField.CorporateEmail]: {
    corporateEmail: (data) => data || null
  },
  [GeneralSettingsFormField.ReportEmail]: {
    reportsEmail: (data) => data || null
  },
  [GeneralSettingsFormField.ContactUsEmail]: {
    supportEmail: (data) => data || null
  },
  [GeneralSettingsFormField.ContactUsTelegramLink]: {
    supportTelegramLink: (data) => data || null
  },
  [GeneralSettingsFormField.ContactUsWhatsappLink]: {
    supportWhatsappLink: (data) => data || null
  },
  [GeneralSettingsFormField.NotificationEmail]: {
    notificationsEmail: (data) => data || null
  },
  [GeneralSettingsFormField.WelcomeMessageText]: {
    welcomeMessageText: (data) => data || null
  },
  [GeneralSettingsFormField.DefaultLanguage]: {
    language: (data) => data || null
  },
  [GeneralSettingsFormField.Domains]: {
    domains: (data) => data || []
  },
  [GeneralSettingsFormField.SisterCountries]: {
    sisterCountries: (data) => data || []
  },
  [GeneralSettingsFormField.StorageFolderName]: {
    storageFolderName: (data) => data || null
  },
  [GeneralSettingsFormField.StorageMaxEmailsImagesMale]: {
    storageMaxEmailsImagesMale: (data) => data || null
  },
  [GeneralSettingsFormField.StorageMaxEmailsImagesFemale]: {
    storageMaxEmailsImagesFemale: (data) => data || null
  },
  [GeneralSettingsFormField.StorageMaxNotificationsImagesMale]: {
    storageMaxNotificationsImagesMale: (data) => data || null
  },
  [GeneralSettingsFormField.StorageMaxNotificationsImagesFemale]: {
    storageMaxNotificationsImagesFemale: (data) => data || null
  },
  [GeneralSettingsFormField.UsersShouldRequestProfileDeletion]: {
    shouldRequestProfileDeletion: (data) => data
  },
  [GeneralSettingsFormField.EnabledMultiCurrencies]: {
    enabledMultiCurrencies: (data) => data
  },
  [GeneralSettingsFormField.PayThroughPaymentDomain]: {
    payThroughPaymentDomain: (data) => data
  },

  [GeneralSettingsFormField.FreeMessages]: {
    freeMessagesAmounts: (data) =>
      data
        .filter(({ customerType }) => !!customerType)
        .map(({ customerType, messageAmount, conversationAmount }) => ({
          userType: customerType as string,
          messagesNum: messageAmount as number,
          conversationsNum: conversationAmount as number
        }))
  },
  [GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto]: {
    viewableProfilesNumBeforePhotosUpload: (data) =>
      data
        .filter(({ customerType }) => !!customerType)
        .map(({ customerType, customersAmount }) => ({
          userType: customerType as string,
          profilesNum: customersAmount as number
        }))
  },
  [GeneralSettingsFormField.RequiredPremiumProfiles]: {
    requiredPremiumProfiles: (data) =>
      data
        .filter(({ customerType }) => !!customerType)
        .map(({ customerType, gender }) => ({
          userType: customerType as string,
          gender: gender as string
        }))
  },
  [GeneralSettingsFormField.FreeLikesCount]: {
    freeLikesCount: (data) => data[FormField.FreeLikesAmount] || null,
    premiumLikesPerDayCount: (data) =>
      data[FormField.PremiumLikesAmountPerDay] || null
  },
  [GeneralSettingsFormField.LengthUnit]: {
    lengthUnit: (data) => data || null
  },
  [GeneralSettingsFormField.AutoLabelsAfterRegistration]: {
    autoLabels: (data) =>
      data
        .filter(({ gender }) => !!gender)
        .map(({ gender, label }) => ({
          gender: gender as string,
          labels: label
        }))
  },
  [GeneralSettingsFormField.RateForAutoBlock]: {
    autoBlockingBadWordsRate: serializeAutoBlockingBadWordsRate
  },
  [GeneralSettingsFormField.RateForBlockingFloods]: {
    autoBlockingFloodsRate: serializeAutoBlockingFloodsRate
  },
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessage]: {
    generalMessageWarnBadWords: (data) => data
  },
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescription]: {
    generalProfileWarnBadWords: (data) => data
  },
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessage]: {
    generalMessageBlockBadWords: (data) => data
  },
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer]: {
    specificMessageWarnBadWords: serializeSpecificBadWords
  },
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]: {
    specificProfileWarnBadWords: serializeSpecificBadWords
  },
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer]: {
    specificMessageBlockBadWords: serializeSpecificBadWords
  },
  [GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]: {
    userInteractionRestricts: serializeCustomerRestrictions
  },
  [GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]: {
    requiredSendChatAccessRequestProfiles:
      serializeRequiredSendChatAccessRequestProfiles
  },
  [GeneralSettingsFormField.StartedConversationsPerDayLimit]: {
    startedConversationsPerDayCount: (data) => data ?? null
  }
};

export const serializeGeneralSettingsRequestData = ({
  data,
  checkPermissions
}: {
  data: Partial<GeneralSettingsFormData>;
  checkPermissions: (restrictions: ResourceActionPermission) => boolean;
}): UpdateGeneralSettingsRequestData => {
  const output = Object.keys(data).reduce((acc, field) => {
    const obj = serializeGeneralSettingsMap[field];

    Object.keys(obj).forEach((key) => {
      // @ts-ignore
      acc[key] = obj[key](data[field]);
    });

    return acc;
  }, {} as UpdateGeneralSettingsRequestData);

  if (
    !checkPermissions({
      resource: PermissionResource.SettingsPassword,
      actions: PermissionAction.Update
    })
  ) {
    delete output['viewConversationsPassword'];
  }

  if (
    !checkPermissions({
      resource: PermissionResource.SettingsCorporateEmail,
      actions: PermissionAction.Update
    })
  ) {
    delete output['corporateEmail'];
  }

  return output;
};
