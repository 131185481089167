import { Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { AccountStatus } from 'enum/api';
import { useModalActions, useUpdateCustomer } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { getFormFieldsListBySchema } from 'utils';
import { isNotEmpty } from 'utils/validation';

import { DeactivateCustomerAccountFormData } from './DeactivateCustomerAccount.types';
import {
  deactivateCustomerAccountFormSchema,
  deactivateCustomerAccountValidationSchema
} from './DeactivateCustomerAccount.utils';

export const DeactivateCustomerAccount = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useUpdateCustomer(true);

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.DeactivateCustomerAccount,
    {
      onClose: reset
    }
  );

  const form = useForm<DeactivateCustomerAccountFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(deactivateCustomerAccountValidationSchema)
  });

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = form;

  const isSubmitDisabled = !isNotEmpty(
    dirtyFields,
    getFormFieldsListBySchema(deactivateCustomerAccountFormSchema)
  );

  const onSubmit = (onSuccess: () => void) =>
    handleSubmit((data) => {
      const { comment } = data;

      if (meta) {
        mutate(
          {
            id: meta.customerId,
            status: AccountStatus.Deactivated,
            adminComment: comment as string
          },
          {
            onSuccess
          }
        );
      }
    });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: isSubmitDisabled,
      onClick: (onClose) => {
        onSubmit(onClose)();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.deactivate_customer_account')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Text marginBottom="20px">
        {t('attribute.description.basic_action_question', {
          action: t('attribute.title.deactivate_customer_account').toLowerCase()
        })}
      </Text>
      <FormProvider {...form}>
        <FormConstructor formSchema={deactivateCustomerAccountFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
