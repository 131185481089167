import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const PayThroughPaymentDomain = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.pay_through_payment_domain')}
      </GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Toggle}
          name={GeneralSettingsFormField.PayThroughPaymentDomain}
        />
      </GridItem>
    </Fragment>
  );
};
