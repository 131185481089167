import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey, ParameterType, PaymentSubscriptionPeriod } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { CreateNewSubscriptionFormData } from './CreateNewSubscription.types';

export const createNewSubscriptionFormSchema = {
  fields: {
    [FormField.SubscriptionTypeName]: {
      type: InputElement.Input,
      translationKey: 'type_name',
      getPlaceholder: () => 'Type name'
    },
    [FormField.SubscriptionDisplayName]: {
      type: InputElement.Input,
      translationKey: 'display_name',
      getPlaceholder: () => 'Display name (optional)',
      hintTranslationKey: 'subscription_template_input'
    },
    [FormField.Comment]: {
      type: InputElement.Input,
      translationKey: 'description',
      getPlaceholder: () => 'Description',
      hintTranslationKey: 'subscription_template_input'
    },
    [FormField.SubscriptionProgramType]: {
      type: InputElement.Select,
      translationKey: 'program_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings)
    },
    [FormField.Period]: {
      type: InputElement.Select,
      translationKey: 'subscription_period',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SubscriptionPeriod, t, settings)
    },
    [FormField.SubscriptionSplitBy]: {
      type: InputElement.Select,
      translationKey: 'subscription_split_by',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SubscriptionPeriod, t, settings)
    },
    [FormField.SubscriptionOrder]: {
      type: InputElement.NumberInput,
      translationKey: 'subscription_order',
      getPlaceholder: () => '(optional)',
      min: 0
    },
    [FormField.SubscriptionCurrencySign]: {
      type: InputElement.Input,
      translationKey: 'currency_sign',
      getPlaceholder: () => '(optional)'
    },
    [FormField.Duration]: {
      type: InputElement.NumberInput,
      translationKey: 'subscription_duration',
      getPlaceholder: () => '',
      min: 0
    },
    [FormField.SumPaid]: {
      type: InputElement.NumberInput,
      translationKey: 'price',
      getPlaceholder: () => '',
      min: 0,
      precision: 2
    },
    [FormField.Currency]: {
      type: InputElement.Select,
      translationKey: 'currency',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Currency, t, settings)
    },
    [FormField.SubscriptionShowTotal]: {
      type: InputElement.Toggle,
      translationKey: 'show_total',
      getPlaceholder: () => 'Show total'
    },
    [FormField.SubscriptionShowSeparately]: {
      type: InputElement.Toggle,
      translationKey: 'show_separately',
      getPlaceholder: () => 'Show below'
    },
    [FormField.SubscriptionIsRenewing]: {
      type: InputElement.Toggle,
      translationKey: 'is_renewing',
      getPlaceholder: () => 'Renewing'
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      isSearchable: true,
      translationKey: 'countries',
      getPlaceholder: () => '',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.CustomerType]: {
      type: InputElement.Select,
      translationKey: 'customer_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
    },
    [FormField.Gender]: {
      type: InputElement.Select,
      translationKey: 'gender',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings)
    },
    [FormField.StripePriceId]: {
      type: InputElement.Input,
      translationKey: 'stripe_price',
      getPlaceholder: () => '(optional)'
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.SubscriptionTypeName} ${FormField.SubscriptionTypeName} ${FormField.SubscriptionTypeName}"
      "${FormField.SubscriptionDisplayName} ${FormField.SubscriptionDisplayName} ${FormField.SubscriptionDisplayName}"
      "${FormField.Comment} ${FormField.Comment} ${FormField.Comment}"
      "${FormField.SubscriptionProgramType} ${FormField.Period} ${FormField.SubscriptionCurrencySign}"
      "${FormField.Duration} ${FormField.SumPaid} ${FormField.Currency}"
      "${FormField.SubscriptionSplitBy} ${FormField.SubscriptionOrder} ${FormField.StripePriceId}"
      "${FormField.SubscriptionUserType} ${FormField.SubscriptionGender} ."
      "${FormField.SubscriptionShowTotal} ${FormField.SubscriptionShowSeparately} ${FormField.SubscriptionIsRenewing}"
      "${FormField.Countries} ${FormField.Countries} ${FormField.Countries}"
    `,
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: 3,
    columnGap: 3
  }
} as const;

export const baseCreateNewSubscriptionValidationSchema: yup.SchemaOf<CreateNewSubscriptionFormData> =
  yup.object({
    [FormField.SubscriptionTypeName]: yup.string().required(),
    [FormField.SubscriptionDisplayName]: yup
      .string()
      .defined()
      .matches(/^\{[^}\s]*\}$/, 'invalid_subscription_template'),
    [FormField.Comment]: yup
      .string()
      .required()
      .matches(/^\{[^}\s]*\}$/, 'invalid_subscription_template'),
    [FormField.SubscriptionProgramType]: yup.string().required(),
    [FormField.Period]: yup
      .mixed()
      .required()
      .oneOf(getAllEnumValues(PaymentSubscriptionPeriod)),
    [FormField.SubscriptionSplitBy]: yup
      .mixed()
      .required()
      .oneOf(getAllEnumValues(PaymentSubscriptionPeriod)),
    [FormField.SubscriptionOrder]: yup.number().positive().optional(),
    [FormField.SubscriptionCurrencySign]: yup.string().defined(),
    [FormField.Duration]: yup.number().positive().required(),
    [FormField.SumPaid]: yup.number().required(),
    [FormField.Currency]: yup.string().required(),
    [FormField.Countries]: yup.array(yup.string().required()),
    [FormField.SubscriptionShowTotal]: yup.boolean().defined(),
    [FormField.SubscriptionShowSeparately]: yup.boolean().defined(),
    [FormField.SubscriptionIsRenewing]: yup.boolean().defined(),
    [FormField.SubscriptionUserType]: yup.string().required(),
    [FormField.SubscriptionGender]: yup.string().required(),
    [FormField.StripePriceId]: yup.string().defined()
  });

export const createNewSubscriptionValidationSchema: yup.SchemaOf<CreateNewSubscriptionFormData> =
  baseCreateNewSubscriptionValidationSchema.concat(
    yup.object({
      [FormField.Period]: yup
        .mixed()
        .required()
        .oneOf(getAllEnumValues(PaymentSubscriptionPeriod)),
      [FormField.SubscriptionSplitBy]: yup
        .mixed()
        .required()
        .oneOf(getAllEnumValues(PaymentSubscriptionPeriod)),
      [FormField.SubscriptionOrder]: yup.number().positive().optional(),
      [FormField.Duration]: yup.number().positive().required(),
      [FormField.SumPaid]: yup.number().min(0, 'positive_value').required()
    })
  );
